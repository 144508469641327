<script lang="ts">
  import { afterUpdate, onMount } from 'svelte'
  import Markdown from 'svelte-exmarkdown'
  import Icon from '@iconify/svelte'

  enum DataLayerEvent {
    start = 'arti-chat-open',
    end = 'arti-chat-end',
    questionAsked = 'arti-chat-question',
    answerReceived = 'arti-chat-answer',
    linkClicked = 'arti-chat-link-click',
  }

  // @ts-ignore
  window.dataLayer = window.dataLayer || []

  const dataLayerPush = (event: DataLayerEvent, value: any) => {
    // @ts-ignore
    window.dataLayer.push({
      event,
      eventCategory: 'arti-chat',
      value,
    })
  }

  type Conversation = {
    user: string
    ai: string
    citations?: Record<string, any>[]
  }

  let show = process.env.NODE_ENV === 'development'
  let promptInput: any
  let submitButton: any
  let closeButton: any
  let disclaimerButton: any
  let conversationWindow: any
  let conversation: Conversation[] = []
  let currentMessage: Conversation | null = null
  let conversationId: string | null = null
  let settings: Record<string, any> = { startingQuestion: [] }
  let focusedIndex = -1 // Index of the currently focused item in the listbox
  let optionSelected = 0
  let showDisclaimer = false
  let aiResponse = ''
  let citations: Record<string, any>[] | undefined = undefined

  const ARTI_SERVICE_URL = process.env.ARTI_SERVICE_URL
  const LOCAL_STORAGE_KEY = 'arti-chat-settings'
  const previousHistory = localStorage?.getItem(LOCAL_STORAGE_KEY)

  if (previousHistory) {
    let prev = JSON.parse(previousHistory)
    conversationId = prev.conversationId
    // conversation = prev.conversation || []
  }

  /**
   * Scrolls the chat window
   */
  const scrollWindow = () => {
    if (!conversationWindow) return
    conversationWindow.scrollTop = conversationWindow.scrollHeight
  }

  const handleResponse = (chunk: string) => {
    try {
      const citTag = '<|CIT|>'

      if (chunk.includes(citTag)) {
        const startIndex = chunk.indexOf(citTag) + citTag.length
        const endIndex = chunk.lastIndexOf(citTag)
        const jsonText = chunk.substring(startIndex, endIndex)
        const result = chunk.substring(endIndex + citTag.length)
        const metadata = JSON.parse(jsonText)
        citations ||= metadata.citations
        currentMessage.citations = citations
        conversationId ||= metadata.conversationId
        aiResponse += result
      } else {
        aiResponse += chunk
      }

      currentMessage = { ...currentMessage, ai: aiResponse, citations: citations }
    } catch (error) {
      throw new Error('Could not extract metadata from response')
    }
  }

  /**
   * Handles the click event on the listbox
   */
  const handleToggleModal = () => {
    if (show) {
      dataLayerPush(DataLayerEvent.end, conversationId)
      show = false
    } else {
      dataLayerPush(DataLayerEvent.start, conversationId)
      show = true
    }
  }

  /**
   * Handles the keydown event on the listbox
   *
   * TODO: This is pretty gnarly and should be refactored
   * @param event
   */
  const handleKeydown = (event: KeyboardEvent) => {
    event.preventDefault()
    const targets = document.querySelectorAll<HTMLOptionElement>('.arti__conversation-starters-list-item')

    if (event.key === 'ArrowUp' || (event.shiftKey && event.key === 'Tab')) {
      optionSelected = optionSelected - 1

      if (optionSelected === -1) {
        optionSelected = 0
        submitButton.focus()
      } else {
        targets[optionSelected]?.focus()
      }
    } else if (event.key === 'ArrowDown' || event.key === 'Tab') {
      optionSelected = optionSelected + 1

      if (optionSelected >= targets.length) {
        optionSelected = 0
        closeButton.focus()
      } else {
        targets[optionSelected]?.focus()
      }
    } else if (event.key === 'Enter') {
      const target = targets[optionSelected]
      handleSubmit(undefined, target.textContent)
    }
  }

  /**
   * Creates a focus trap for the modal
   *
   * @param event
   */
  const handleModalKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      if (event.shiftKey) {
        if (document.activeElement === promptInput) {
          event.preventDefault()
          promptInput.focus()
        }
      } else {
        if (document.activeElement === disclaimerButton) {
          event.preventDefault()
          disclaimerButton.focus()
        }
      }
    }
  }

  /**
   * Handle the focus event on the listbox
   *
   * This sets the focusedIndex to the index of the currently focused item
   * in the listbox. This is used to determine which item should be focused
   */
  const handleListFocus = () => {
    const firstChild = document.querySelector<HTMLButtonElement>('.arti__conversation-starters-list-item:first-child')
    focusedIndex = -1

    if (firstChild) firstChild.focus()
  }

  /**
   * resets the default state
   */
  const finishResponse = () => {
    conversation = [...conversation, currentMessage]
    currentMessage = null
    promptInput.disabled = false
    aiResponse = ''
    citations = undefined

    setTimeout(() => {
      scrollWindow()
    }, 50)
  }

  /**
   * handles the form submit event
   *
   * @param e
   * @param input
   */
  const handleSubmit = async (e: Event | undefined, input?: string) => {
    e?.preventDefault()
    const value = input || promptInput.value
    promptInput.value = '' // reset the value
    promptInput.disabled = true // disable the input while we wait for the response
    currentMessage = { user: value, ai: 'Generating your answer! 👩🏻‍💻 This might take a moment...', citations: [] }

    dataLayerPush(DataLayerEvent.questionAsked, value)

    // Why....
    new Promise(() => setTimeout(scrollWindow, 10))

    try {
      const response = await fetch(`${ARTI_SERVICE_URL}/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          conversationId,
          prompt: value,
        }),
      })

      // @ts-ignore
      if (!response.ok) throw new Error(response.statusText)
      if (!response.body) throw new Error('No response from the server')

      let done = false
      const reader = response.body.getReader()
      const decoder = new TextDecoder()

      while (!done) {
        const { value, done: doneReading } = await reader.read()
        done = doneReading
        handleResponse(decoder.decode(value))
        scrollWindow()
      }

      dataLayerPush(DataLayerEvent.answerReceived, { answer: aiResponse, citations: citations })
      finishResponse()
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * Handles the click event on the close button
   */
  window.addEventListener('toggle-arti', handleToggleModal)

  /**
   * handles clicking out of the modal
   */
  document.addEventListener('DOMContentLoaded', () => {
    const artiModal = document.getElementById('arti-modal')

    artiModal?.addEventListener('click', (event) => {
      if (event.target !== artiModal) return
      window.dispatchEvent(new Event('toggle-arti'))
    })

    document.querySelectorAll<HTMLElement>('[arti-control]')?.forEach((node) => {
      node.addEventListener('click', (e) => {
        e?.preventDefault()
        window.dispatchEvent(new Event('toggle-arti'))
      })
    })
  })

  // Handle keyboard shortcuts for triggering the modal
  document.addEventListener('keydown', (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
      event.preventDefault() // prevent the default behavior
      show = true
    }

    if (event.key === 'Escape') {
      event.preventDefault() // prevent the default behavior
      show = false
    }
  })

  // use a reactive statement to clear the input element when show is false
  $: if (!show) {
    afterUpdate(() => {
      promptInput.value = ''
      document.body.style.overflow = 'auto'
    })
  }

  // use a reactive statement to automatically focus on the input element when show is true
  $: if (show) {
    afterUpdate(() => {
      promptInput.focus()
      document.body.style.overflow = 'hidden'
    })
  }

  $: if (currentMessage) {
    scrollWindow()
  }

  $: if (conversation.length) {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ conversationId, conversation }))
    scrollWindow()
  }

  $: onMount(() => {
    fetch(`${ARTI_SERVICE_URL}/settings`)
      .then((resp) => resp.json())
      .then((data) => {
        if (!data?.settings) return
        settings = data.settings
      })
  })

  function handleCitationClick(event: MouseEvent & { currentTarget: EventTarget & HTMLAnchorElement }) {
    event.preventDefault()
    const link = event.currentTarget.href
    dataLayerPush(DataLayerEvent.linkClicked, { link })
    // resume the default behavior
    window.location.href = link
  }
</script>

<div id="arti-modal" style="display: {show ? 'flex' : 'none'}" on:keydown={handleModalKeydown}>
  <div id="arti-content">
    <div id="arti-body">
      {#if conversation.length || currentMessage}
        <div class="arti__conversation-window" bind:this={conversationWindow}>
          {#each conversation as message}
            <div class="arti__conversation-block">
              <div class="arti__user-input">{message.user}</div>
              <div class="arti__ai-response">
                <Markdown md={message.ai} />
              </div>
              <ul class="arti__citations">
                {#each message.citations as citation}
                  <li>
                    <a href={citation.link} on:click={handleCitationClick}>
                      <Icon icon="mdi:link-variant" style="min-width: 18px;" />
                      <span>{citation.title}</span>
                    </a>
                  </li>
                {/each}
              </ul>
            </div>
          {/each}
          {#if currentMessage}
            <div class="arti__conversation-block">
              <div class="arti__user-input">{currentMessage.user}</div>
              <div class="arti__ai-response">
                <Markdown md={currentMessage.ai || 'Generating your answer! 👩🏻‍💻 This might take a moment...'} />
              </div>
              <ul class="arti__citations">
                {#if currentMessage?.citations}
                  {#each currentMessage?.citations as citation}
                    <li>
                      <a href={citation.link}>
                        <Icon icon="mdi:link-variant" style="min-width: 18px;" />
                        <span>{citation.title}</span>
                      </a>
                    </li>
                  {/each}
                {/if}
              </ul>
            </div>
          {/if}
        </div>
      {/if}
      <form class="arti__form" on:submit={handleSubmit}>
        <div class="arti__input-group">
          <input
            class="arti__prompt-input"
            name="prompt"
            type="text"
            autocomplete="off"
            bind:this={promptInput}
            placeholder="Ask me a question..."
            tabindex="0"
          />

          <button class="arti__submit-button" type="submit" tabindex="0" bind:this={submitButton}>
            <Icon icon="ph:feather" style="font-size: 2em" />
          </button>
        </div>

        {#if !currentMessage}
          <div
            class="arti__conversation-starters"
            style="display: {conversation.length || currentMessage ? 'none' : 'block'}"
          >
            <div class="eyebrow">Examples of what you can ask me:</div>
            <ul
              class="arti__conversation-starters-list"
              role="listbox"
              aria-labelledby="example-questions"
              id="example-questions"
              on:keydown={handleKeydown}
              on:focus={handleListFocus}
              tabindex="0"
            >
              {#each settings.startingQuestions || [] as question, index}
                <li
                  class="arti__conversation-starters-list-item"
                  role="option"
                  aria-selected={focusedIndex === index}
                  tabindex="-1"
                >
                  <button on:click={() => handleSubmit(undefined, question)} data-index={index}>
                    <Icon icon="mdi:arrow-right" style="font-size: 1em;" />
                    <span>{question}</span>
                  </button>
                </li>
              {/each}
            </ul>
          </div>
        {/if}
        <button
          id="arti-modal-close"
          type="button"
          aria-label="Close modal"
          title="Close"
          on:click={handleToggleModal}
          bind:this={closeButton}
        >
          <Icon icon="mdi:close" style="font-size: 2em" />
        </button>
      </form>
    </div>
    {#if settings?.disclaimerText}
      <div id="arti-footer">
        {#if showDisclaimer}
          <div class="arti-footer__disclaimer-statement">
            <Markdown md={settings.disclaimerText} />
          </div>
        {/if}
        <button type="button" class="arti-footer__disclaimer-link" on:click={() => (showDisclaimer = !showDisclaimer)}>
          {showDisclaimer ? 'Close' : 'Disclaimer'}
        </button>
      </div>
    {/if}
  </div>
</div>

<style>
  :root {
    --arti-base-font: 16px;
    --arti-font-color: #202633;
    --arti-font-family: Manrope, sans-serif;
    --arti-line-height: 1.6em;
    --arti-modal-background-color: rgba(255, 255, 255, 0.5);
    --arti-modal-max-height: 80vh;
    --arti-content-background-color: rgba(255, 255, 255, 1);
    --arti-content-max-width: 800px;
    --arti-content-border-radius: 0.75em;
    --arti-content-padding: 1.2em;
    --arti-content-box-shadow: 2px 4px 8px hsl(0deg 0% 0% / 0.1);
    --arti-input-border-color: #cccbcb;
    --arti-input-font-color: #202633;
    --arti-input-font-size: 1.1em;
    --arti-button-color: #f4f4f4;
    --arti-button-hover-color: #c1c1c1;
  }

  #arti-modal {
    align-items: start;
    backdrop-filter: blur(2px);
    background: var(--arti-modal-background-color);
    border-radius: var(--arti-content-border-radius);
    box-sizing: border-box;
    color: var(--arti-font-color);
    font-family: var(--arti-font-family);
    font-size: var(--arti-base-font);
    justify-content: center;
    line-height: var(--arti-line-height);
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    padding: var(--arti-content-padding);
  }

  #arti-body {
    max-height: var(--arti-modal-max-height);
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
  }

  #arti-footer {
    text-align: right;
    position: absolute;
    right: 1.5em;
    bottom: 0.25em;
  }

  .arti-footer__disclaimer-link {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 0.3em;
    border: 0;
    color: #272727;
    cursor: pointer;
    filter: drop-shadow(0.2em 0.5em 0.8em rgba(0, 0, 0, 0.2));
    font-size: 0.75em;
    letter-spacing: 0.1em;
    padding: 0.5em 1em;
    text-transform: uppercase;
  }

  .arti-footer__disclaimer-link:hover {
    background: rgba(255, 255, 255, 1);
  }

  .arti-footer__disclaimer-statement {
    background: #fff;
    filter: drop-shadow(0.2em 0.5em 0.8em rgba(0, 0, 0, 0.2));
    max-width: 80vw;
    min-width: 30vw;
    padding: 1.4em;
    text-align: left;
  }

  #arti-content {
    background: var(--arti-content-background-color);
    border-radius: var(--arti-content-border-radius);
    box-shadow: var(--arti-content-box-shadow);
    margin-top: calc((100vh - var(--arti-modal-max-height)) / 2);
    max-height: var(--arti-modal-max-height);
    width: var(--arti-content-max-width);
  }

  .arti__input-group {
    display: flex;
    padding: var(--arti-content-padding);
  }

  .arti__input-group input {
    flex: 1;
    position: relative;
  }

  #arti-content input,
  #arti-content button {
    outline-color: #989898;
  }

  .arti__conversation-window {
    display: flex;
    flex-direction: column;
    row-gap: 1.5em;
    overflow-y: scroll;
    padding: 0 var(--arti-content-padding);
    margin: var(--arti-content-padding) 0;
    max-width: 100%;
    margin-bottom: 1em;
  }

  .arti__user-input {
    font-weight: 600;
    margin-top: 0.2em;
  }

  .arti__ai-response {
    margin-top: 0.2em;
  }

  .arti__prompt-input {
    border-radius: 0.5em 0 0 0.5em;
    border: 1px solid var(--arti-input-border-color);
    color: var(--arti-input-font-color);
    height: 100%;
    padding: 0.9em;
  }

  #arti-modal-close {
    background-color: transparent;
    border: 0;
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
    position: fixed;
    top: 1em;
    right: 1em;
  }

  .arti__submit-button {
    align-items: center;
    background: var(--arti-button-color);
    border-left: 0;
    border-radius: 0 0.5em 0.5em 0;
    border: 1px solid var(--arti-input-border-color);
    color: #7f7f7f;
    cursor: pointer;
    display: flex;
    justify-content: center;
    min-width: 5em;
  }

  .arti__submit-button:hover {
    color: #2a2a2a;
  }

  .arti__conversation-block:last-child {
    margin-bottom: 1em;
  }

  .arti__conversation-starters {
    background: rgba(255, 255, 255, 1);
    border-radius: --var(--arti-content-border-radius);
    border: 1px solid rgba(0, 0, 0, 0.2);
    filter: drop-shadow(0 0 0.05em rgba(0, 0, 0, 0.1));
    font-size: 0.8em;
    left: 5px;
    right: 5px;
    padding: var(--arti-content-padding);
    position: absolute;
    top: 48px;
    margin: var(--arti-content-padding);
  }

  .arti__conversation-starters .eyebrow {
    color: #7f7f7f;
    font-family: sans-serif;
    font-size: 0.95em;
    font-style: italic;
  }

  .arti__conversation-starters ul {
    list-style: none;
    padding-left: 0;
    font-size: var(--arti-base-font);
  }

  .arti__conversation-starters button {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    border: 0;
    cursor: pointer;
    display: block;
    font-size: 1em;
    padding: 0.875em 0.5em;
    text-align: left;
    transition: background 250ms ease-in-out;
    width: 100%;
    font-size: 1em;
  }
  .arti__conversation-starters button:hover {
    background-color: var(--arti-input-border-color);
  }

  .arti__citations {
    margin: 0;
    list-style: none;
    padding: 0;
    display: flex;
    column-gap: 0.3em;
    flex-wrap: wrap;
  }

  .arti__citations li {
    max-width: 100%;
  }

  .arti__citations span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .arti__citations a {
    align-items: center;
    border-radius: 2em;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.8);
    column-gap: 0.35em;
    display: flex;
    margin-bottom: 0.35em;
    padding: 0.25em 1em;
    text-decoration: none;
  }

  .arti__citations a:hover {
    border: 1px solid rgba(0, 0, 0, 0.4);
    color: rgba(0, 0, 0, 1);
  }
</style>
